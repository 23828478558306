import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Container from 'common/src/components/UI/Container';
import {
  RootWrapper,
  LogoWrapper,
  IconWrapper,
  TextDivWrapper,
  TextWrapper,
  TextBlockWrapper,
} from './security.style';
import Logo from 'common/src/assets/image/saas/logo_small.svg';
import BackupIcon from 'common/src/assets/image/saas/backup.svg';
import DatabaseIcon from 'common/src/assets/image/saas/database.svg';
import HostingIcon from 'common/src/assets/image/saas/hosting.svg';
import EncryptionIcon from 'common/src/assets/image/saas/encryption.svg';
const texts = [
  {
    title: 'Hosting on GCP',
    secondary:
      'SalesOcean is hosted on one of the most trusted Cloud Platforms out there: Google Cloud Platform. GCP is known for its extensive security features, from the physical layer (data centers) up to the software layer. They provide great scaleability and minimal downtime. We are proud to be hosted on one of world most trusted cloud platforms.',
    icon: HostingIcon,
  },
  {
    title: 'Database prodiver',
    secondary:
      'All data in our system that belongs to you instance is yours! We do not alter, view or mine your database without your permission. Moveover, our databases are maintained by one of the most trusted leaders in the database industry: MongoDB Atlas. They provide us with great flexibility and ensure uptime.',
    icon: DatabaseIcon,
  },
  {
    title: 'Real-time backups',
    secondary:
      'We backup all data for a period of at least 7 days. Backups are made in real-time on secondary nodes, so no downtime. This means you never have to worry about losing data. On top of that we provide an archive functionality that stored critical data when deleted, for a period of 30 days, you can recover this data directly inside SalesOcean.',
    icon: BackupIcon,
  },
  {
    title: 'Encryption',
    secondary:
      'Sensitive data is encrypted by industry standard encryption. We do not save passwords and leverage secure Oauth2.0 authentication where possible.',
    icon: EncryptionIcon,
  },
];

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
}) => {
  return (
    <RootWrapper id="security">
      <LogoWrapper>
        <div className="FeatureSliderInnerX">
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <Container>
          <Box className="FeatureSliderX">
            <img src={Logo} alt="logo" style={{ width: '12rem' }} />
          </Box>
        </Container>
      </LogoWrapper>
      <TextDivWrapper>
        {texts.map(text => (
          <TextBlockWrapper>
            <IconWrapper>
              <img src={text.icon} />
            </IconWrapper>
            <TextWrapper>
              <Heading content={text.title} />
              <Text content={text.secondary} />
            </TextWrapper>
          </TextBlockWrapper>
        ))}
      </TextDivWrapper>
    </RootWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
